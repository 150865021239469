import * as React from "react"

import Layout from "../components/layout/layout"
import HomeChecklistSection from "../components/sections/home-checklist-section"
import HomeCtaSection from "../components/sections/home-cta-section"
import HomeDavidSection from "../components/sections/home-david-section"
import HomeHeroSection from "../components/sections/home-hero-section"
import HomeIntroSection from "../components/sections/home-intro-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Fully Managed IT Network Support"
      description="Managed IT Services Orange County"
    />
    <HomeHeroSection />
    <HomeIntroSection />
    <HomeChecklistSection />
    <HomeDavidSection />
    <HomeCtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default IndexPage
